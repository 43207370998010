export enum ResponseStatusCode {
    OK = 200,
    Created,
    Accepted,

    BadRequest = 400,
    Unauthorized,
    PaymentRequired,
    Forbidden,
    NotFound,

    InternalError = 500
}
export enum Usertype {
   Admin=0,
   Employer=1,
   Employee=2
}
export enum Workstatus {
    Working = 1,
    DayOff = 2
}
