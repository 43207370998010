import { Injectable } from '@angular/core';
import { HttpService } from './common/http.service';
import { StorageService } from './common/storage.service';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  refreshtoken = localStorage.getItem('refreshtoken');
  constructor(
    private _httpService: HttpService,
    private _storageService: StorageService
  ) {}

  get isLoggedIn() {
    return !!this._storageService.user;
  }

  get isTokenValid() {
    return !!this._storageService.JWTToken;
    // var tokenExpiry = new Date(this._storageService.user.exp * 1000);
    //   var today = new Date();
    //   if (tokenExpiry < today) {
    //     return false
    //   }
    //   else{
    //     return true
    //   }
  }
  getRefreshToken(showGlobalLoader: boolean = false) {
    return this._httpService.postAsQueryString(
      'User/GetNewTokensAsync?token=' +
        this._storageService.JWTToken +
        '&refreshToken=' +
        this.refreshtoken,
      showGlobalLoader
    );
  }
  login(loginModel: any, showGlobalLoader: boolean = true) {
    //debugger
    return this._httpService.post(
      'Customer/AdminLogin',
      loginModel,
      showGlobalLoader
    );
  }
  getresetpasswordlink(data: any, showGlobalLoader: boolean = true) {
    return this._httpService.post(
      'User/SendLinkForResetPassword?EmailId=' + data,
      data,
      showGlobalLoader
    );
  }
  getresetpassword(data: any, showGlobalLoader: boolean = true) {
    return this._httpService.post('User/ResetPassword', data, showGlobalLoader);
  }
  checkresetpasswordlinkExpiry(data: any, showGlobalLoader: boolean = true) {
    return this._httpService.postAsQueryString(
      'User/CheckResetPasswordLinkExpiry?secretUserId=' + data,
      showGlobalLoader
    );
  }
}
