// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

 // baseUrl: `https://localhost:5001/api/`,
  // test
  baseUrl: `https://tapi.netlinkamc.com/api/`,
  ImageUrl: `https://tapi.netlinkamc.com/`,
  resetlink: `http://test1.netlinkamc.com/#/reset-password/`,

  //live
  // baseUrl: `https://api.netlinkamc.com/api/`,
  // ImageUrl:`https://api.netlinkamc.com/`,
  // resetlink:`https://netlinkamc.com/#/reset-password/`,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
