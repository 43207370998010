import { Injectable } from '@angular/core';
import { AuthUser } from 'src/app/models/auth-user';
import { TokenService } from './token.service';

const USER = "user";
const JWT_TOKEN = "jwt_t";
const LAST_PAGE_URL = "last_page_url";

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(private _tokenservice: TokenService) { }

  clearAll(){
    localStorage.removeItem(USER);
    localStorage.removeItem(JWT_TOKEN);
  }

  clearAuth() {
    const token = this.JWTToken;
    this.clearAll();
    this.JWTToken = token;
  }

  public set JWTToken(token: string) {
    localStorage.setItem(JWT_TOKEN, JSON.stringify(token));
  }

  public get JWTToken(): string {
    return JSON.parse(localStorage.getItem(JWT_TOKEN));
  }

  public set LastPageURL(url: string) {
    localStorage.setItem(LAST_PAGE_URL, JSON.stringify(url));
  }

  public get LastPageURL(): string {
    return JSON.parse(localStorage.getItem(LAST_PAGE_URL));
  }

  public get user(): AuthUser { 
    var tokenData = this._tokenservice.getDecodedAccessToken(this.JWTToken);
    (localStorage.setItem(USER, JSON.stringify(tokenData)));
    return tokenData;
  }

  public set user(user: AuthUser) {
    localStorage.setItem(USER, JSON.stringify(user));
  }


  setItem(name,value) {
    localStorage.setItem(name, JSON.stringify(value));
  }

  getItem(key){
    return JSON.parse(localStorage.getItem(key));
  }
}
