<button class="close" mat-button (click)="dialogRef.close()">x</button>
<div class="row mt-2 mainclass">
    <div class="maindiv">
      <!-- <p class=" ml-4 popuphead">{{title}}</p> -->
   <div class="mt-4">
    
       <div class="row   ml-1 mt-4" [formGroup]="dataForm">
           <div class="align-center ">
            <div class="row">
                   <div class="col-lg-3">
                    <label class="popuphead" >From</label>
                </div>
                <div class="col-lg-9"> 
                  <input type="date" class="form-control signupinput" formControlName="Fromdate">
                    <!-- <div class="form-group">
                                        
                        <mat-form-field appearance="outline" >
                                           
                            <input  matInput [matDatepicker]="Fromdate" readonly placeholder="Choose a date" 
                       
                              autocomplete="off"  formControlName="Fromdate" >
                               
                            <mat-datepicker-toggle matSuffix [for]="Fromdate"></mat-datepicker-toggle>
                            <mat-datepicker #Fromdate></mat-datepicker>
                              </mat-form-field>
                              <small
                              class="text-danger"
                              *ngIf="
                              dataForm.controls.Fromdate.hasError('required') &&
                                (dataForm.controls.Fromdate.dirty ||
                                dataForm.controls.Fromdate.touched) 
                                
                              "
                              ><br>From-date is required</small
                            >
                    </div> -->
                </div>
            </div>
            <div class="row mt-3 mb-4">
                <div class="col-lg-3">
                    <label class="popuphead" >To</label>
                </div>
                <div class="col-lg-9"> 
                  <input type="date" class="form-control signupinput" formControlName="Todate">
                    <!-- <div class="form-group">
                                      
                        <mat-form-field appearance="outline" >
                                           
                            <input matInput [matDatepicker]="Todate" readonly placeholder="Choose a date"
                         
                               autocomplete="off" formControlName="Todate" [min]="minDate" >
                               
                            <mat-datepicker-toggle matSuffix [for]="Todate"></mat-datepicker-toggle>
                            <mat-datepicker #Todate></mat-datepicker>
                              </mat-form-field>
                              <small
                              class="text-danger"
                              *ngIf="
                              dataForm.controls.Todate.hasError('required') &&
                                (dataForm.controls.Todate.dirty ||
                                dataForm.controls.Todate.touched) 
                                
                              "
                              ><br>To-date is required</small
                            >
                              <small
                              class="text-danger"
                              *ngIf="
                              dataForm.controls.Todate.invalid &&
                                (dataForm.controls.Todate.dirty ||
                                dataForm.controls.Todate.touched) 
                                
                              "
                              ><br>Please input valid dates</small
                            >
                        </div> -->
                </div>

            </div>
               <button  class="addbutton  ml-3"  (click)="Submit()" [disabled]="dataForm.invalid"
              >Filter </button>
             <button  class="cancelbutton ml-4 mb-3" (click)="reset()">Clear</button>
           </div>
       </div>
   </div>
  </div>
  </div>
