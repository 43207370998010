import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './components/loader/loader.component';
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { TwoDigitDecimaNumberDirective } from './directives/twodigitdecimalnumber.directive';
import { LowerCasePipe } from './pipes/lower-case.pipe';
import { AlertTemplateComponent } from './alert-template/alert-template.component';
import {MatIconModule} from '@angular/material/icon'
import { MatDialogModule } from '@angular/material/dialog';
import { DateFilterComponent } from './date-filter/date-filter.component';

// import {
//   MatToolbarModule,
//   MatMenuModule,
//   MatButtonModule,
//   MatDatepickerModule,
//   MatFormFieldModule,
//   MatTableModule,
//   MatTabsModule,
//   MatProgressBarModule,
//   MatSortModule,
//   MatPaginatorModule,
//   MatCheckboxModule,
//   MatInputModule,
//   MatNativeDateModule,
//   MatChipsModule,
//   MatSelectModule,
//   MatStepperModule,
//   MatTooltipModule,
//   MatAutocompleteModule,
//   MatButtonToggleModule,
//   MatSlideToggleModule,
//   MatIconModule,
//   MatDialogModule,
//   MatSnackBarModule,
//   MatSliderModule,
//   MatCardContent, MatCardModule, MatCard
// } from '@angular/material';

@NgModule({
  declarations: [LoaderComponent, TwoDigitDecimaNumberDirective, LowerCasePipe,
     AlertTemplateComponent, DateFilterComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatIconModule,
    MatDialogModule,
  ],
  entryComponents: [
    AlertTemplateComponent,
    
  ],
  exports:[
    CommonModule,
    TwoDigitDecimaNumberDirective,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    LoaderComponent,
    LowerCasePipe,
    AlertTemplateComponent,
    DateFilterComponent,
    // angular material modules
    // MatTableModule,
    // MatTabsModule,
    // MatPaginatorModule,
    // MatSortModule,
    // MatToolbarModule,
    // MatMenuModule,
    // MatButtonModule,
    // MatDatepickerModule,
    // MatFormFieldModule,
    // MatProgressBarModule,
    // MatCheckboxModule,
    // MatInputModule,
    // MatNativeDateModule,
    // MatChipsModule,
    // MatSelectModule,
    // MatStepperModule,
    // MatTooltipModule,
    // MatAutocompleteModule,
    // MatButtonToggleModule,
    // MatSlideToggleModule,
    // MatDialogModule,
    // MatSnackBarModule,
    // MatSliderModule,
    // MatIconModule,
    // MatCard,
    // MatCardContent, 
  ],
  providers:[]
})
export class SharedModule { 
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: SharedModule
    };
  }
}
