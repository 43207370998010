import { Component,Inject, OnInit } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA,MatDialogRef } from '@angular/material/dialog';

import { FormGroup, FormBuilder, Validator, Validators } from '@angular/forms';
import { AccountService } from 'src/app/services/account.service';

import { ResponseStatusCode } from 'src/app/constants/response-status-code.enum';
import { Usertype } from 'src/app/constants/response-status-code.enum';
import { StorageService } from 'src/app/services/common/storage.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import {MatIconModule} from '@angular/material/icon';
import { MainService} from 'src/app/services/main.service';

@Component({
  selector: 'app-alert-template-component',
  templateUrl: 'alert-template.component.html',
  styleUrls: ['./alert-template.component.css']
})
export class AlertTemplateComponent {
  iconType: string;
  iconPosition: string;
  title: string;
  text: string;
  options: false;
  input: false;
  button: string;
  inputData: boolean=true;

  constructor(
    public dialogRef: MatDialogRef<AlertTemplateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // this.iconType = data.iconType;
    // this.iconPosition = data.iconPosition ? data.iconPosition : 'default';
     this.title = data.title;
    // this.text = data.text;
    // this.options = data.options;
    // this.input = data.input;
    // this.button = data.button;

    // if (data.time) {
    //   setTimeout(() => {
    //     this.dialogRef.close();
    //   }, data.time);
    // }
  }
  close(){
    this.dialogRef.close(true);
  }
}
