
import { Component,Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {MatDialog, MAT_DIALOG_DATA,MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Moment } from 'moment';
@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.css']
})
export class DateFilterComponent implements OnInit {
  title: any;
  inputData: boolean=true;
  dataForm: FormGroup;
  constructor(  public dialogRef: MatDialogRef<DateFilterComponent>,
    private fb: FormBuilder,
    private readonly router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any) {    
        this.title = data.title;}

  ngOnInit(): void {
    this.dataForm = this.fb.group(
      {
        Fromdate:[ ,[Validators.required]],
        Todate:[,[Validators.required]],
      },
     
    );
  }
  
  Submit(){
    console.log(this.dataForm.value)
    localStorage.setItem('Fromdate',this.dataForm.value.Fromdate);
    localStorage.setItem('Todate',this.dataForm.value.Todate)
    this.router.navigate(["reports"]);
    this.dialogRef.close(true);
  }
  
  close(){
    this.dialogRef.close(true);
  }
  reset(){
    this.dataForm.reset();
  }

}
