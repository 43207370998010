import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, tap } from 'rxjs/operators';
import { StorageService } from 'src/app/services/common/storage.service';
import { AccountService } from 'src/app/services/account.service';
import { NavigationEnd, Router } from '@angular/router';
import { NotificationService } from 'src/app/services/common/notification.service';
import { environment } from '../../../../environments/environment';
import { HttpService } from 'src/app/services/common/http.service';

export const AUTH_HEADER = 'Authorization';
export const rootPath = environment.baseUrl;

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private readonly _accountService: AccountService,
    private readonly _storageService: StorageService,
    private readonly _router: Router,
    private readonly _notificationService: NotificationService,
    private _httpService: HttpService
  ) {
    this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (
          this._router.url != '/login' &&
          this._router.url != '/subpoena-pickup-status' &&
          this._router.url != '/login-request' &&
          this._router.url != '/contact-us'
        ) {
          this._storageService.LastPageURL = this._router.url;
        }
      });
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let req = request;

    if (this._accountService.isTokenValid) {
      req = req.clone({
        setHeaders: {
          [AUTH_HEADER]: `Bearer ${this._storageService.JWTToken}`,
        },
      });
    }
    // else{

    //   this._accountService.getRefreshToken().subscribe((res : any) => {
    //     //debugger;
    //     if (res.Status==true) {
    //       this._storageService.JWTToken = res.Token;
    //       localStorage.setItem('refreshtoken', res.RefreshToken )

    //     }
    //   });
    // }
    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && event.url.includes(rootPath)) {
          const header = event.headers.get(AUTH_HEADER);
          if (header) {
            this._storageService.JWTToken = header;
          }
        }
      }),
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          //TODO: Token refreshing
          this._accountService.getRefreshToken().subscribe(
            (res: any) => {
              //debugger;
              if (res.Status == true) {
                this._storageService.JWTToken = res.Token;
                localStorage.setItem('refreshtoken', res.RefreshToken);
              } else {
                this._storageService.clearAll();
                this._router.navigate(['login']);
              }
            },
            (err: any) => {
              this._storageService.clearAll();
              this._router.navigate(['login']);
            }
          );
        }
        return throwError(err);
      })
    );
  }
}
