import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lowerCase'
})
export class LowerCasePipe implements PipeTransform {

  transform(value: string): string {
    
     if(value!=null){
      return value.toLowerCase();
     }
     return "";
  }

}
