
<div class="row mt-4 mainclass">
  <div class="maindiv mt-3">
    <p class=" ml-4 popuphead">{{title}}</p>
 <div class="mt-4">
  
     <div class="row   ml-1 mt-4">
         <div class="align-center ">
            
             <button  class="addbutton  ml-3"  [mat-dialog-close]="inputData" 
            >Yes </button>
           <button  class="cancelbutton ml-4" (click)="dialogRef.close()">No</button>
         </div>
     </div>
 </div>
</div>
</div>